import ApiService from '@/common/api/api.service';

let apiBasePath = 'Estoque';

const EstoqueService = {
    async saidaManual(params) {
        let { data } = await ApiService.put(`${apiBasePath}/saida`, params);
        return data;
    },
    async estorno(estoqueMovimentoId) {
        let { data } = await ApiService.put(
            `${apiBasePath}/estorno?estoqueMovimentoId=${estoqueMovimentoId}`
        );
        return data;
    },
    async transferencia(body) {
        let { data } = await ApiService.put(
            `${apiBasePath}/transferencia`,
            body
        );
        return data;
    },
    async obterPorFiltro(params) {
        let { data } = await ApiService.get(
            `${apiBasePath}/obter-por-filtro`, { params }
        );
        return data;
    },
    async obterRelatorioEstoque(params) {
        let { data } = await ApiService.get(
            `${apiBasePath}/imprimir-estoque`, { params }
        );
        return data;
    }
};

export default EstoqueService;
