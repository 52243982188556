<template>
  <modal
    id="modalSuprimentoEstoqueTransferenciaDeposito"
    titulo="SUPRIMENTOS.TRANSFERENCIA_DEPOSITOS"
    :exibir="exibir"
    :campos="$refs"
    @fechar="fecharModal"
  >
    <form-estoque :opcoes="opcoes" :form="form" @refs="getRefs" />
    <template #modal-footer>
      <div class="d-flex justify-content-between w-100">
        <b-button variant="secondary" @click="fecharModal">
          {{ $t('GERAL.FECHAR') }}
        </b-button>
        <b-button variant="primary" @click="salvar">
          {{ $t('GERAL.SALVAR') }}
        </b-button>
      </div>
    </template>
  </modal>
</template>
<script>
// Utils & Aux:
import helpers from '@/common/utils/helpers';
import { START_LOADING, STOP_LOADING } from '@/store/Store';
import mensagem from '@/common/utils/mensagem';

// Components:
import FormEstoque from './Form.vue';
import Modal from '@/components/modal/Modal';

//service
import EstoqueService from '@/common/services/estoque/estoque.service';

export default {
  props: {
    exibir: { type: Boolean, default: false },
    opcoes: { type: Object },
  },
  components: {
    Modal,
    FormEstoque,
  },
  data() {
    return {
      refs: [],
      form: {
        produtoId: '',
        depositoAntigoId: '',
        quantidade: 1,
        depositoNovoId: '',
      },
    };
  },
  methods: {
    // FUNÇÕES VALIDAÇÃO FORMULÁRIO:
    getRefs(refs) {
      this.refs = refs;
    },
    validarFormulario() {
      return helpers.validarFormulario(this.refs);
    },
    // FUNÇÕES CRUD:
    salvar() {
      if (!this.validarFormulario()) return;
      this.$store.dispatch(START_LOADING);
      EstoqueService.transferencia(this.form)
        .then(() => {
          mensagem.sucesso(
            this.$t('GERAL.SUCESSO'),
            this.$t('SUPRIMENTOS.TRANSFERENCIA_REALIZADA').formatUnicorn({
              item: this.$t('SUPRIMENTOS.ESTOQUE'),
            })
          );
          this.form = {
            produtoId: '',
            depositoAntigoId: '',
            quantidade: 1,
            depositoNovoId: '',
          };
          this.$emit('tranferenciaDepositoConcluída');
          this.fecharModal();
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    // FUNÇÕES MODAL:
    fecharModal() {
      this.$emit('fechar', 'estoque');
    },
  },
};
</script>
