<template>
  <b-row>
    <b-col md="6">
      <input-select-search
        ref="produtoId"
        v-model="filtro.produtoId"
        :label="$t('SUPRIMENTOS.SELECIONAR_PRODUTO')"
        :options="opcoes.produtos"
      />
    </b-col>
    <b-col md="6">
      <input-select-search
        ref="depositoId"
        v-model="filtro.depositoId"
        :label="$t('SUPRIMENTOS.SELECIONAR_DEPOSITO')"
        :options="opcoes.depositos"
      />
    </b-col>
    <b-col md="auto">
      <b-button variant="primary" class="w-100 mb-3" @click="filtrar">
        {{ $t('GERAL.FILTRAR') }}
      </b-button>
    </b-col>
    <b-col md="auto">
      <b-button variant="secondary" class="w-100 mb-3" @click="limparFiltro">
        {{ $t('GERAL.LIMPAR') }}
      </b-button>
    </b-col>
    <b-col md="auto">
      <b-button class="w-100 mb-3" variant="info" @click="saidaManual">
        {{ $t('SUPRIMENTOS.SAIDA_MANUAL_PRODUTO') }}
      </b-button>
    </b-col>
    <b-col md="auto">
      <b-button variant="info" class="w-100 mb-3" @click="transferencia">
        {{ $t('SUPRIMENTOS.TRANSFERENCIA_DEPOSITOS') }}
      </b-button>
    </b-col>
  </b-row>
</template>
<script>
// Utils & Aux:
import helpers from '@/common/utils/helpers';
import mensagem from '@/common/utils/mensagem';
import modais from '@/common/utils/modais';
import { START_LOADING, STOP_LOADING } from '@/store/Store';

// Components:
import { InputSelectSearch } from '@/components/inputs';

// services
import DepositoService from '@/common/services/depositos/depositos.service';
import ProdutoService from '@/common/services/produtos/produtos.service';

export default {
  components: {
    InputSelectSearch,
  },
  data() {
    return {
      filtro: {
        depositoId: '',
        produtoId: '',
      },
      modais: {
        produto: false,
        deposito: false,
      },
      opcoes: {
        produtos: [],
        depositos: [],
      },
      limpar: false,
    };
  },
  mounted() {
    this.getDepositos();
    this.getProdutos();
  },

  watch: {
    filtro: {
      handler(value) {
        this.$emit('filtro', value);
      },
      deep: true,
      immediate: true,
    },
  },

  methods: {
    // obtenção de dados
    getDepositos() {
      this.$store.dispatch(START_LOADING);
      DepositoService.obterTodos()
        .then(({ data }) => {
          this.opcoes.depositos = data.map((item) => ({
            text: item.descricao,
            value: item.id,
          }));
        })
        .catch(({ error }) => {
          mensagem.erro(this.$t('GERAL.ERRO'), error.response.data.errors);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    getProdutos() {
      this.$store.dispatch(START_LOADING);
      ProdutoService.obterTodos()
        .then(({ data }) => {
          this.opcoes.produtos = data.map((item) => ({
            text: `${item.codigo} - ${item.descricao}`,
            value: item.id,
          }));
        })
        .catch((error) => {
          mensagem.erro(this.$t('GERAL.ERRO'), error.response.data.errors);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    // FILTRO ESTOQUE
    // FUNÇÕES VALIDAÇÃO:
    validarFormulario() {
      return helpers.validarFormulario(this.$refs);
    },
    // FUNÇÕES FILTRO:
    filtrar() {
      if (!this.validarFormulario()) return;
      this.$emit('filtrar', {
        produtoId: this.filtro.produtoId,
        depositoId: this.filtro.depositoId,
      });
    },
    limparFiltro() {
      this.limpar = true;
      this.$emit('limpar', (this.filtro = {}));
    },
    // FUNÇÕES MODAL:
    openModal(key, item) {
      return modais.abrirModal(this, key, item);
    },
    closeModal(key) {
      return modais.fecharModal(this, key);
    },
    saidaManual() {
      this.$emit('saida-manual', 'saidaManual');
    },
    transferencia() {
      this.$emit('transferencia', 'transferencia');
    },
  },
};
</script>
