<template>
  <div class="row">
    <div class="col-12">
      <input-select-search
        ref="produto"
        v-model="form.produtoId"
        :label="$t(`SUPRIMENTOS.SELECIONE_PRODUTO_PARA_RETIRADA`)"
        :options="opcoes.produtos"
        required
      />
    </div>
    <div class="col-12">
      <input-select-search
        ref="deposito"
        v-model="form.depositoId"
        :label="$t(`SUPRIMENTOS.SELECIONE_DEPOSITO_PARA_RETIRADA`)"
        :options="opcoes.depositos"
        isDisabled
      />
    </div>
    <div class="col-6">
      <input-text
        ref="quantidadeTotal"
        v-model="form.quantidadeTotal"
        type="number"
        :label="$t('SUPRIMENTOS.QUANTIDADE_TOTAL')"
        disabled
      />
    </div>
    <div class="col-6">
      <input-text
        ref="quantidade"
        v-model="form.quantidade"
        type="number"
        :label="$t('SUPRIMENTOS.QUANTIDADE')"
        :placeholder="$t('GERAL.SOMENTE_NUMEROS')"
        :min="1"
      />
    </div>
    <div class="col-12">
      <input-text
        ref="descricao"
        v-model="form.descricao"
        :label="$t('SUPRIMENTOS.DESCRICAO_MOVIMENTO_RETIRANTE')"
        :placeholder="$t('SUPRIMENTOS.DESCRICAO')"
        type="text"
        :minLength="1"
        :maxLength="50"
        required
      />
    </div>
  </div>
</template>
<script>
import { InputText, InputSelectSearch } from '@/components/inputs';
import DepositoService from '@/common/services/depositos/depositos.service';
import { START_LOADING, STOP_LOADING } from '@/store/Store';
import mensagem from '@/common/utils/mensagem';

export default {
  props: {
    form: { type: Object, default: Object },
    opcoes: {
      type: Object,
      default: () => ({
        depositos: [],
        produtos: [],
      }),
    },
  },
  components: {
    InputText,
    InputSelectSearch,
  },
  mounted() {
    this.$emit('refs', this.$refs);
  },
  watch: {
    'form.produtoId'() {
      if (this.form.produtoId) {
        this.buscaDepositoQuantidadeTotal();
      }
    },
  },
  methods: {
    buscaDepositoQuantidadeTotal() {
      this.$store.dispatch(START_LOADING);
      DepositoService.obterDepositoQuantidadePorProduto(this.form.produtoId)
        .then(({ data }) => {
          this.form.depositoId = data?.id;
          this.form.quantidadeTotal = data?.quantidadeProduto;
        })
        .catch(({ error }) => {
          mensagem.erro(this.$t('GERAL.ERRO'), error.response.data.errors);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
  },
};
</script>
