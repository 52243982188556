<template>
  <topbar
    :titulo="$t('SUPRIMENTOS.ESTOQUE')"
    :subtitulo="$t('SUPRIMENTOS.DESCRICAO_TELA')"
    :breadcrumbs="breadcrumbs"
  />
</template>
<script>
// Utils & Aux:
import Topbar from '@/layouts/full-layout/horizontal-sidebar/Topbar.vue';
import rotas from '@/common/utils/rotas';

export default {
  components: { Topbar },
  data() {
    return {
      breadcrumbs: [
        { titulo: 'SUPRIMENTOS.SUPRIMENTOS' },
        { titulo: 'SUPRIMENTOS.ESTOQUE' },
      ],
    };
  },
  methods: {
    cadastrar() {
      return rotas.irPara('criar-nf');
    },
  },
};
</script>
