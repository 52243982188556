<template>
  <div class="row">
    <div class="col-12">
      <input-select-search
        ref="produto"
        v-model="form.produtoId"
        :label="$t(`SUPRIMENTOS.SELECIONE_PRODUTO_PARA_TRANSFERENCIA`)"
        :options="opcoes.produtos"
        required
      />
    </div>

    <div class="col-12">
      <input-select-search
        ref="depositoVelho"
        v-model="form.depositoAntigoId"
        :label="$t(`SUPRIMENTOS.SELECIONE_DEPOSITO_ANTIGO`)"
        :options="depositos"
        required
      />
    </div>

    <div class="col-12">
      <input-select-search
        ref="depositoNovo"
        v-model="form.depositoNovoId"
        :label="$t(`SUPRIMENTOS.SELECIONE_DEPOSITO_NOVO`)"
        :options="depositos"
        required
      />
    </div>

    <div class="col-12">
      <input-text
        ref="quantidade"
        v-model="form.quantidade"
        :label="$t('SUPRIMENTOS.QUANTIDADE')"
        :placeholder="$t('GERAL.SOMENTE_NUMEROS')"
        type="number"
        :min="1"
      />
    </div>
  </div>
</template>
<script>
//utils e aux
import { START_LOADING, STOP_LOADING } from '@/store/Store';
import mensagem from '@/common/utils/mensagem';

// Components:
import { InputText, InputSelectSearch } from '@/components/inputs';
// import { SelecionarDeposito } from '@/components/selecionar-entidades/modal/index';
import DepositoService from '@/common/services/depositos/depositos.service';

export default {
  props: {
    form: { type: Object, default: Object },
    opcoes: {
      type: Object,
      default: () => ({
        depositos: [],
        produtos: [],
      }),
    },
  },
  components: {
    InputSelectSearch,
    InputText,
  },
  data() {
    return {
      depositos: [],
    };
  },
  mounted() {
    this.$emit('refs', this.$refs);
    this.getDepositos();
  },
  methods: {
    getDepositos() {
      this.$store.dispatch(START_LOADING);
      DepositoService.obterTodos()
        .then(({ data }) => {
          this.depositos = data.map((deposito) => ({
            depositoNovoId: deposito.id,
            text: deposito.descricao,
            value: deposito.id,
          }));
        })
        .catch(({ error }) => {
          mensagem.erro(this.$t('GERAL.ERRO'), error.response.data.errors);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
  },
};
</script>
