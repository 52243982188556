<template>
  <div>
    <titulo-estoques />

    <!-- filtro tabela -->
    <filtro-estoque
      @filtro="setFiltro"
      @saida-manual="abrirModal"
      @transferencia="abrirModal"
      @filtrar="getEstoques"
      @limpar="limparFiltro"
    />

    <span v-if="filtrandoPor.depositoId">
      Quantidade total estoque: <span>{{ totalProduto }}</span>
    </span>
    <!-- tabela -->
    <tabela-estoque :items="items" @atualizar="getEstoques" />

    <paginacao
      v-model="paginacao"
      :total="paginacao.total"
      @input="getEstoques"
    />

    <!-- modais -->
    <modal-saida-manual
      :exibir="modaisVisibilidade.saidaManual"
      :opcoes="opcoes"
      @fechar="() => fecharModal('saidaManual')"
      @saidaManualConcluida="atualizarTabela"
    />

    <modal-transferencia-deposito
      :exibir="modaisVisibilidade.transferencia"
      :opcoes="opcoes"
      @fechar="() => fecharModal('transferencia')"
      @tranferenciaDepositoConcluída="atualizarTabela"
    />
  </div>
</template>
<script>
// Utils & Aux:
import { START_LOADING, STOP_LOADING } from '@/store/Store';
import breadcrumb from '@/common/utils/breadcrumb';
import mensagem from '@/common/utils/mensagem';

// Service:
import EstoqueService from '@/common/services/estoque/estoque.service';
import ProdutoService from '@/common/services/produtos/produtos.service';
import DepositoService from '@/common/services/depositos/depositos.service';

// Components:
import FiltroEstoque from './components/filtro/Index';
import TabelaEstoque from './components/Tabela';
import ModalSaidaManual from './components/modais/saida-manual/Index';
import ModalTransferenciaDeposito from './components/modais/transferencia-deposito/Index';
import Paginacao from '@/components/paginacao/Paginacao';
import TituloEstoques from '@/views/suprimentos/estoques/components/TituloEstoques.vue';

export default {
  components: {
    TituloEstoques,
    FiltroEstoque,
    TabelaEstoque,
    Paginacao,
    ModalTransferenciaDeposito,
    ModalSaidaManual,
  },
  mounted() {
    breadcrumb.definir(this.$store, [
      { titulo: 'SUPRIMENTOS.SUPRIMENTOS' },
      { titulo: 'SUPRIMENTOS.ESTOQUE' },
    ]);
    this.getProdutos();
    this.getDepositos();
  },
  data() {
    return {
      items: [],
      totalProduto: 0,
      filtrandoPor: {
        depositoId: null,
        produtoId: null,
      },
      paginacao: {
        numeroDaPagina: 1,
        tamanhoDaPagina: 5,
        total: 0,
      },
      modaisVisibilidade: {
        transferencia: false,
        saidaManual: false,
      },
      opcoes: {
        // produtos: [],
        depositos: [],
      },
    };
  },
  watch: {},
  methods: {
    getEstoques() {
      this.$store.dispatch(START_LOADING);
      EstoqueService.obterPorFiltro(this.filtrandoPor)
        .then(({ data }) => {
          const movimentacoesEstoque = [];
          data.forEach((obj) => {
            const {
              produtoId,
              depositoId,
              estoqueMovimentos,
              depositoDescricao,
              quantidadeTotal,
              valorTotal,
            } = obj;

            estoqueMovimentos.forEach((movimento) => {
              movimentacoesEstoque.push({
                depositoAntigoId: depositoId,
                depositoNovoId: '',
                depositoDescricao,
                quantidadeTotal,
                produtoId,
                depositoId,
                valorTotal,
                ...movimento,
              });
            });
          });

          this.totalProduto = movimentacoesEstoque[0]?.quantidadeTotal ?? 0;

          this.items = movimentacoesEstoque;
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },

    getProdutos() {
      this.$store.dispatch(START_LOADING);
      ProdutoService.obterTodos()
        .then(({ data }) => {
          this.opcoes.produtos = data.map((prod) => ({
            produtoId: prod.id,
            text: `${prod.codigo} - ${prod.descricao}`,
            value: prod.id,
          }));
        })
        .catch((error) => {
          mensagem.erro(this.$t('GERAL.ERRO'), error.response.data.errors);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },

    getDepositos() {
      this.$store.dispatch(START_LOADING);
      DepositoService.obterTodos()
        .then(({ data }) => {
          this.opcoes.depositos = data.map((deposito) => ({
            depositoNovoId: deposito.id,
            text: deposito.descricao,
            value: deposito.id,
          }));
        })
        .catch(({ error }) => {
          mensagem.erro(this.$t('GERAL.ERRO'), error.response.data.errors);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    // funções tabela
    atualizarTabela() {
      this.getEstoques();
    },
    // FUNÇÕES MODAL:
    abrirModal(modal) {
      this.modaisVisibilidade[modal] = true;
    },

    fecharModal(modal) {
      this.modaisVisibilidade[modal] = false;
    },
    // FUNÇÕES DE ESTORNO:
    async confirmarEstorno(item) {
      const respostaEstorno = await mensagem.confirmacao(
        this.$t('GERAL.CONFIRMAR_ESTORNO'),
        this.$t('GERAL.CONFIRMAR_ESTORNO_ITEM').formatUnicorn({
          item: `${item.descricao}`,
        })
      );
      respostaEstorno.isConfirmed ? this.estornar(item.id) : {};
    },

    setFiltro(value) {
      this.filtrandoPor = value;
    },

    limparFiltro() {
      this.items = [];
    },
  },
};
</script>
