import ApiService from '@/common/api/api.service'

let apiBasePath = 'Deposito'

const DepositosService = {
    // FUNÇÕES CRUD:
    async criar(form) {
        let { data } = await ApiService.post(`${apiBasePath}`, form)
        return data
    },
    async editar(form) {
        let { data } = await ApiService.put(`${apiBasePath}`, form)
        return data
    },
    async excluir(itemId) {
        let { data } = await ApiService.delete(`${apiBasePath}/${itemId}`)
        return data
    },
    async obterDeposito(itemId) {
        let { data } = await ApiService.get(`${apiBasePath}/${itemId}`)
        return data
    },
    async obterDepositoQuantidadePorProduto(itemId) {
        let { data } = await ApiService.get(`${apiBasePath}/obter-deposito-por-produto/${itemId}`)
        return data
    },
    async obterTodos(form) {
        let { data } = await ApiService.get(`${apiBasePath}`, form)
        return data
    }
}

export default DepositosService