<template>
  <div>
    <b-table
      :fields="fields"
      :items="items"
      :empty-text="$t('GERAL.NENHUM_REGISTRO')"
      head-variant="light"
      stacked="md"
      responsive
      show-empty
      striped
      hover
    >
      <template #cell(dataMovimento)="item">
        <span>{{ formatDateToShow(item.item.dataMovimento) }}</span>
      </template>
      <template #cell(quantidade)="item">
        <span
          v-if="item.item.tipoMovimentoEstoque === saida"
          class="text-danger"
          >{{ item.item.quantidade }}</span
        >
        <span v-else class="text-success">{{ item.item.quantidade }}</span>
      </template>
      <template #cell(acoes)="item">
        <b-button
          v-if="item.item.tipoMovimentoEstoque === saida"
          variant="secondary"
          @click="confirmarEstorno(item.item.id)"
        >
          {{ $t('GERAL.ESTORNAR') }}
        </b-button>
      </template>
    </b-table>
  </div>
</template>
<script>
// Utils & Aux:
import { START_LOADING, STOP_LOADING } from '@/store/Store';
import mensagem from '@/common/utils/mensagem';
import datas from '@/common/utils/datas';

// Service:
import EstoqueService from '@/common/services/estoque/estoque.service';

export default {
  props: {
    items: { type: Array, default: Array },
    total: { type: Number, default: Number },
  },
  data() {
    return {
      saida: 85,
      fields: [
        {
          key: 'depositoDescricao',
          label: this.$t('SUPRIMENTOS.DEPOSITO'),
          sortable: true,
        },
        {
          key: 'dataMovimento',
          label: this.$t('SUPRIMENTOS.DATA'),
          sortable: true,
        },
        {
          key: 'descricaoTipoMovimentoEstoque',
          label: this.$t('SUPRIMENTOS.TIPO_MOVIMENTO'),
          sortable: true,
        },
        {
          key: 'quantidade',
          label: this.$t('SUPRIMENTOS.QUANTIDADE'),
          sortable: true,
        },
        {
          key: 'descricao',
          label: this.$t('SUPRIMENTOS.DETALHES_MOVIMENTO'),
          sortable: true,
        },
        {
          key: 'acoes',
          label: this.$t('SUPRIMENTOS.ACOES'),
        },
      ],
    };
  },
  methods: {
    confirmarEstorno(itemId) {
      this.$store.dispatch(START_LOADING);
      EstoqueService.estorno(itemId)
        .then(() => {
          mensagem.sucesso(
            this.$t('GERAL.SUCESSO'),
            this.$t('GERAL.ITEM_ESTORNADO').formatUnicorn({
              item: this.$t('SUPRIMENTOS.ESTOQUE'),
            })
          );
          this.$emit('atualizar');
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },

    // utilitários
    formatDateToShow(date) {
      return datas.formatDateToShow(date); // Use o utilitário datas para formatar a data corretamente
    },
  },
};
</script>
