<template>
  <modal
    key="estoque"
    id="modalSuprimentoEstoqueSaidaManual"
    titulo="SUPRIMENTOS_ESTOQUE.SAIDA_MANUAL_ESTOQUE"
    :exibir="exibir"
    :campos="$refs"
    @fechar="fecharModal"
  >
    <form-estoque :opcoes="opcoes" :form="form" @refs="getRefs" />
    <template #modal-footer>
      <div class="d-flex justify-content-between w-100">
        <b-button variant="secondary" @click="fecharModal">
          {{ $t('GERAL.FECHAR') }}
        </b-button>
        <b-button variant="primary" @click="saidaManual">
          {{ $t('SUPRIMENTOS_ESTOQUE.CONFIRMAR_SAIDA_MANUAL') }}
        </b-button>
      </div>
    </template>
  </modal>
</template>

<script>
// Utils & Aux:
import { START_LOADING, STOP_LOADING } from '@/store/Store';
import mensagem from '@/common/utils/mensagem';
import helpers from '@/common/utils/helpers';

// Service:
import EstoqueService from '@/common/services/estoque/estoque.service';

// Components:
import FormEstoque from './Form.vue';
import Modal from '@/components/modal/Modal';

export default {
  props: {
    exibir: { type: Boolean, default: false },
    opcoes: { type: Object },
  },
  components: {
    Modal,
    FormEstoque,
  },
  data() {
    return {
      refs: [],
      form: {
        depositoId: '',
        produtoId: '',
        quantidade: 1,
        descricao: '',
        dataMovimento: new Date(),
        tipoMovimentoEstoque: 'Entrada',
        contabiliza: true,
      },
    };
  },
  methods: {
    getRefs(refs) {
      this.refs = refs;
    },
    validarFormulario() {
      return helpers.validarFormulario(this.refs);
    },
    saidaManual() {
      if (!this.validarFormulario()) return;

      if (!this.form.quantidadeTotal) {
        mensagem.warning(
          this.$t('GERAL.AVISO'),
          this.$t('ACADEMICO.QUANTIDADE_TOTAL_SEM_ESTOQUE')
        );
        return;
      }
      if (this.form.quantidade > this.form.quantidadeTotal) {
        mensagem.warning(
          this.$t('GERAL.AVISO'),
          this.$t('ACADEMICO.QUANTIDADE_MAIOR_QUANTIDADE_TOTAL')
        );
        return;
      }
      this.$store.dispatch(START_LOADING);
      EstoqueService.saidaManual(this.form)
        .then(() => {
          mensagem.sucesso(
            this.$t('GERAL.SUCESSO'),
            this.$t('SUPRIMENTOS_ESTOQUE.SAIDA_MANUAL_SUCESSO')
          );
          this.limpaModal();
          this.$emit('saidaManualConcluida');
          this.fecharModal();
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    limpaModal() {
      setTimeout(() => {
        this.form = {
          depositoId: '',
          produtoId: '',
          quantidade: 1,
          descricao: '',
          dataMovimento: new Date(),
          tipoMovimentoEstoque: 'Entrada',
          contabiliza: true,
        };
      }, 300);
    },
    fecharModal() {
      this.$emit('fechar');
      this.limpaModal();
    },
  },
};
</script>
